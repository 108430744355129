import * as GQTypes from './graphql-operations';
import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export const CompanyAutocompleteResultFragmentDoc = gql`
    fragment CompanyAutocompleteResult on Company {
  id
  name
  countryAlpha2
  naicsCode
  sAndP {
    id
    sAndPId
  }
  companyWebsite {
    id
    url
  }
  leis {
    id
    lei
    isPrimary
  }
}
    `;
export const CompanyAutocompleteResultWithSourceFragmentDoc = gql`
    fragment CompanyAutocompleteResultWithSource on Company {
  ...CompanyAutocompleteResult
  source
}
    ${CompanyAutocompleteResultFragmentDoc}`;
export const EmailPreview_EmailContentsFragmentDoc = gql`
    fragment EmailPreview_emailContents on EmailContents {
  recipients {
    email
    name
  }
  ccs {
    email
    name
  }
  sender {
    email
    name
  }
  subject
  bodyHtml
  bccs {
    email
    name
  }
}
    `;
export const PageInfoFragmentDoc = gql`
    fragment PageInfo on PageInfo {
  hasNextPage
  hasPreviousPage
  startCursor
  endCursor
}
    `;
export const PageInfoWithCountFragmentDoc = gql`
    fragment PageInfoWithCount on PageInfo {
  hasNextPage
  hasPreviousPage
  startCursor
  endCursor
  totalRowCount
}
    `;
export const ActivityTypeSchemaFragmentDoc = gql`
    fragment ActivityTypeSchema on ActivityTypeSchema {
  id
  activityType
  schema {
    id
    jsonSchema
  }
}
    `;
export const CtsTransformParquetDataFragmentDoc = gql`
    fragment CtsTransformParquetData on ParquetData {
  id
  numRecords
  ndjsonSignedUrl
  ndjsonSize
  ndjsonByteBoundaries
  columns {
    name
    sqlType
    minValue
    maxValue
    numNulls
    approxDistinctValues
    sampleValues
  }
}
    `;
export const CtsTransformRunFieldsFragmentDoc = gql`
    fragment CtsTransformRunFields on CustomerTargetSchemaTransformRun {
  id
  sql
  transform {
    id
    transformTarget
    businessActivityType {
      id
      name
    }
  }
  input {
    id
    name
    category
    revisionRootFileMetadata {
      id
      name
    }
  }
  output {
    ...CtsTransformParquetData
  }
  standardizedOutput {
    ...CtsTransformParquetData
  }
  warnings {
    message
    blocking
  }
  error
  createdAt
  runner {
    id
    name
  }
}
    ${CtsTransformParquetDataFragmentDoc}`;
export const CtsTransformForEditorFragmentDoc = gql`
    fragment CtsTransformForEditor on CustomerTargetSchemaTransform {
  id
  deletedAt
  sql
  transformTarget
  businessActivityType {
    id
    name
  }
  runs {
    ...CtsTransformRunFields
  }
}
    ${CtsTransformRunFieldsFragmentDoc}`;
export const BuildingPeriodInfoFragmentDoc = gql`
    fragment BuildingPeriodInfo on BuildingPeriodInterface {
  kind
  sizeQuantity
  startYearMonth
  endYearMonth
  partialCleanPowerPercent
  cleanPowerContractualInstrument
  powerUsageEffectiveness
  isBuildingEmpty
  customFieldValue
  customFieldId
  orgUnitTypeStableId
  orgUnitStableId
}
    `;
export const BuildingInfoFragmentDoc = gql`
    fragment BuildingInfo on BuildingInterface {
  streetAddress
  streetAddress2
  latitude
  longitude
  postalCode
  city
  state
  country
  latitude
  longitude
  startYearMonth
  endYearMonth
  sizeUnit
  buildingKind
  buildingSubkind
  leaseType
  buildingName
  buildingUniqueId
  isWithoutNaturalGas
  isWithoutRefrigerants
  periods {
    ...BuildingPeriodInfo
  }
  revision
  editState
}
    ${BuildingPeriodInfoFragmentDoc}`;
export const BuildingDiffFragmentDoc = gql`
    fragment BuildingDiff on CadtBuildingDiff {
  old {
    ...BuildingInfo
  }
  new {
    ...BuildingInfo
  }
  errors {
    message
    adminMessage
    code
  }
  buildingPresentInBart
  warnings {
    adminMessage
    message
    code
  }
}
    ${BuildingInfoFragmentDoc}`;
export const CustomFieldForBuldingDiffFragmentDoc = gql`
    fragment CustomFieldForBuldingDiff on IngestionCustomField {
  id
  name
}
    `;
export const OrgUnitTypeForBuildingDiffFragmentDoc = gql`
    fragment OrgUnitTypeForBuildingDiff on OrgUnitType {
  id
  name
  stableId
}
    `;
export const OrgUnitForBuildingDiffFragmentDoc = gql`
    fragment OrgUnitForBuildingDiff on OrgUnit {
  id
  name
  stableId
}
    `;
export const ReviewDocumentParseAttemptFragmentDoc = gql`
    fragment ReviewDocumentParseAttempt on DocumentParseAttempt {
  id
  userUploadId
  modelType
  createdAt
  updatedAt
  utilityUsageData {
    energyConsumptionAmount
    energyConsumptionUnit
    receiverAddress
    serviceAddress
    serviceStartDate
    serviceEndDate
  }
}
    `;
export const FinanceTagForGridFragmentDoc = gql`
    fragment FinanceTagForGrid on FinanceTag {
  id
  name
  description
  columnFormat
  columnConfiguration
  tagType
}
    `;
export const FundAllFieldsFragmentDoc = gql`
    fragment FundAllFields on Fund {
  __typename
  id
  name
  nameSortable
  fundGroup
  fundCategory
  excludeAsSandbox
  anonymousFundUuid
  createdAt
  orgId
  updatedAt
  tagData
  externalId
}
    `;
export const FinanceColumnAssetGridFragmentDoc = gql`
    fragment FinanceColumnAssetGrid on FinanceColumn {
  id
  field
  columnFormat
  columnConfiguration {
    ... on GridColumnConfigurationSingleSelect {
      __typename
      columnFormat
      options
    }
  }
  headerName
  description
  width
}
    `;
export const PermissionFieldsFragmentDoc = gql`
    fragment PermissionFields on PermissionItem {
  id
  objectId
  permission
  object {
    __typename
    id
    name
  }
  revokedAt
}
    `;
export const RoleInfoFragmentDoc = gql`
    fragment RoleInfo on Role {
  id
  name
  description
  permissions {
    ...PermissionFields
  }
}
    ${PermissionFieldsFragmentDoc}`;
export const UserRoleFieldsFragmentDoc = gql`
    fragment UserRoleFields on UserRoleAssignment {
  id
  revokedAt
  role {
    id
    ...RoleInfo
  }
}
    ${RoleInfoFragmentDoc}`;
export const MeasurementProjectFieldsFragmentDoc = gql`
    fragment MeasurementProjectFields on MeasurementProject {
  id
  name
  active
  status
  createdAt
  completedAt
  coverageInterval
  coverageStartDate
  coverageEndDate
  deadline
}
    `;
export const UserUploadTaskFieldsFragmentDoc = gql`
    fragment UserUploadTaskFields on UserUploadTask {
  id
  assignee {
    id
    name
  }
  datasource {
    id
    dataset {
      id
      name
    }
  }
  measurementProject {
    id
    name
    active
    coverageStartDate
    coverageEndDate
    completedAt
  }
}
    `;
export const AssignableUserFieldsFragmentDoc = gql`
    fragment AssignableUserFields on User {
  id
  name
  displayName
}
    `;
export const ReportItemUiFragmentDoc = gql`
    fragment ReportItemUI on IReportItem {
  id
  itemNumber
  label
  sublabel
  optional
  optionalMessage
}
    `;
export const EngagementTaskConfigForTaskCreationFragmentDoc = gql`
    fragment EngagementTaskConfigForTaskCreation on EngagementTaskConfig {
  id
  name
  orgId
  order
  category
  rootFacingDescription
  defaultSupplierFacingDescription
  crossOrgModelId
  engagementTaskInputs
  type
  optionalEngagementTaskInputs
  timeNeeded
  type
}
    `;
export const BusinessActivityTypeLiteFragmentDoc = gql`
    fragment BusinessActivityTypeLite on BusinessActivityType {
  id
  versionName
  versionId
  name
  schemaJson
  tSchema
}
    `;
export const AllBusinessActivityTypeFieldFieldsFragmentDoc = gql`
    fragment AllBusinessActivityTypeFieldFields on BusinessActivityTypeField {
  id
  typeId
  versionId
  description
  fieldName
  fieldType
  fieldValueOneOf
  isRequired
  isProratable
  unitFieldName
}
    `;
export const AllBusinessActivityTypeFieldsFragmentDoc = gql`
    fragment AllBusinessActivityTypeFields on BusinessActivityType {
  id
  versionId
  name
  description
  fields {
    ...AllBusinessActivityTypeFieldFields
  }
}
    ${AllBusinessActivityTypeFieldFieldsFragmentDoc}`;
export const IngestionExampleDataFieldsFragmentDoc = gql`
    fragment IngestionExampleDataFields on IngestionExampleData {
  id
  title
  columns {
    fieldName
    title
    description
    examples
    required
    optionalToMap
    defaultValue
  }
  rows {
    fields {
      fieldName
      value
    }
  }
  datasetId
}
    `;
export const DataIssueUtils_DataIssueFragmentDoc = gql`
    fragment dataIssueUtils_DataIssue on DataIssue {
  comments {
    edges {
      node {
        id
        person {
          id
          isWatershedEmployee
          isWatershedContractor
        }
        createdAt
        isPublished
      }
    }
  }
}
    `;
export const PermissionOriginForFetchOrgUsersForPermissionFragmentDoc = gql`
    fragment PermissionOriginForFetchOrgUsersForPermission on UserPermissionOrigin {
  id
  permission {
    id
    permission
    object {
      id
      name
    }
  }
  role {
    id
    role {
      id
      name
    }
  }
}
    `;
export const UserForAllOrgUsersForPermissionFragmentDoc = gql`
    fragment UserForAllOrgUsersForPermission on User {
  id
  name
  orgAccessId(orgId: $orgId)
  permissionOrigin(permissions: $permissions, orgId: $orgId) {
    ...PermissionOriginForFetchOrgUsersForPermission
  }
}
    ${PermissionOriginForFetchOrgUsersForPermissionFragmentDoc}`;
export const UserForOrgUsersWithPermissionsFragmentDoc = gql`
    fragment UserForOrgUsersWithPermissions on User {
  id
  name
  permissions(orgId: $orgId) {
    id
    permission
  }
  roles(orgId: $orgId) {
    id
    role {
      id
      permissions {
        id
        permission
      }
    }
  }
}
    `;
export const SerializableErrorFieldsFragmentDoc = gql`
    fragment SerializableErrorFields on SerializableError {
  message
  code
  stackTrace
  errorType
  details
}
    `;
export const OverviewReportConfigFieldsFragmentDoc = gql`
    fragment OverviewReportConfigFields on ReportConfig {
  id
  reportType
  shortName
  longName
  description
  brandColor
  status
  isHiddenFromOverview
  isCreationBlocked
  isRegulatoryExposureBlocked
  isCorporateReport
  isFinanceReport
  requiredPermissions
  reportObjectives
}
    `;
export const SetPreferredLocaleDocument = gql`
    mutation SetPreferredLocale($input: SetPreferredLocaleInput!) @withOwner(owner: EnterpriseFoundations) {
  setPreferredLocale(input: $input) {
    preferredLocale
  }
}
    `;

export function useSetPreferredLocaleMutation() {
  return Urql.useMutation<
    GQTypes.GQSetPreferredLocaleMutation,
    GQTypes.GQSetPreferredLocaleMutationVariables
  >(SetPreferredLocaleDocument);
}
export const TypeaheadCompanyWithEfsDocument = gql`
    query TypeaheadCompanyWithEfs($search: String!, $allowMatchById: Boolean!, $searchDemo: Boolean!, $filterHiddenCompanies: Boolean) @withOwner(owner: SupplyChain) {
  companyFuzzyMatchWithEfs(
    q: $search
    allowMatchById: $allowMatchById
    searchDemo: $searchDemo
    filterHiddenCompanies: $filterHiddenCompanies
  ) {
    results {
      ...CompanyAutocompleteResultWithSource
    }
    companyIdsWithSupplierSpecificEfs
  }
}
    ${CompanyAutocompleteResultWithSourceFragmentDoc}`;

export function useTypeaheadCompanyWithEfsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQTypeaheadCompanyWithEfsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQTypeaheadCompanyWithEfsQuery,
    GQTypes.GQTypeaheadCompanyWithEfsQueryVariables
  >({ query: TypeaheadCompanyWithEfsDocument, ...options });
}
export const GetCompanyDocument = gql`
    query getCompany($id: ID) @withOwner(owner: SupplyChain) {
  companyForDashboard(id: $id) {
    ...CompanyAutocompleteResultWithSource
  }
}
    ${CompanyAutocompleteResultWithSourceFragmentDoc}`;

export function useGetCompanyQuery(
  options?: Omit<Urql.UseQueryArgs<GQTypes.GQGetCompanyQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GQTypes.GQGetCompanyQuery,
    GQTypes.GQGetCompanyQueryVariables
  >({ query: GetCompanyDocument, ...options });
}
export const CreateDemoOrgDocument = gql`
    mutation CreateDemoOrg($input: CreateDemoOrgInput!) @withOwner(owner: EnterpriseFoundations) {
  createDemoOrg(input: $input) {
    backgroundJobId
  }
}
    `;

export function useCreateDemoOrgMutation() {
  return Urql.useMutation<
    GQTypes.GQCreateDemoOrgMutation,
    GQTypes.GQCreateDemoOrgMutationVariables
  >(CreateDemoOrgDocument);
}
export const LatestDocumentParseAttemptForReviewDocument = gql`
    query LatestDocumentParseAttemptForReview($fileId: ID!, $modelType: DocumentParseAttemptModelType!) @withOwner(owner: DataIngestion) {
  latestDocumentParseAttempt(fileId: $fileId, modelType: $modelType) {
    ...ReviewDocumentParseAttempt
  }
}
    ${ReviewDocumentParseAttemptFragmentDoc}`;

export function useLatestDocumentParseAttemptForReviewQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQLatestDocumentParseAttemptForReviewQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQLatestDocumentParseAttemptForReviewQuery,
    GQTypes.GQLatestDocumentParseAttemptForReviewQueryVariables
  >({ query: LatestDocumentParseAttemptForReviewDocument, ...options });
}
export const ReviewUserUploadPreviewUrlDocument = gql`
    query ReviewUserUploadPreviewUrl($userUploadId: ID!) @withOwner(owner: DataIngestion) {
  userUpload(id: $userUploadId) {
    userVisibleAttempt {
      previewUrl
    }
  }
}
    `;

export function useReviewUserUploadPreviewUrlQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQReviewUserUploadPreviewUrlQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQReviewUserUploadPreviewUrlQuery,
    GQTypes.GQReviewUserUploadPreviewUrlQueryVariables
  >({ query: ReviewUserUploadPreviewUrlDocument, ...options });
}
export const UpsertDocumentParseAttemptReviewDocument = gql`
    mutation UpsertDocumentParseAttemptReview($input: UpsertDocumentParseAttemptInput!) @withOwner(owner: DataIngestion) {
  upsertDocumentParseAttempt(input: $input) {
    ...ReviewDocumentParseAttempt
  }
}
    ${ReviewDocumentParseAttemptFragmentDoc}`;

export function useUpsertDocumentParseAttemptReviewMutation() {
  return Urql.useMutation<
    GQTypes.GQUpsertDocumentParseAttemptReviewMutation,
    GQTypes.GQUpsertDocumentParseAttemptReviewMutationVariables
  >(UpsertDocumentParseAttemptReviewDocument);
}
export const OrgDatasetsDocument = gql`
    query OrgDatasets @withOwner(owner: EnterpriseFoundations) {
  organization {
    id
    datasets {
      id
      name
    }
  }
}
    `;

export function useOrgDatasetsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrgDatasetsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrgDatasetsQuery,
    GQTypes.GQOrgDatasetsQueryVariables
  >({ query: OrgDatasetsDocument, ...options });
}
export const OrgDatasourcesDocument = gql`
    query OrgDatasources @withOwner(owner: EnterpriseFoundations) {
  organization {
    id
    datasets {
      id
      name
      datasources {
        id
        name
      }
    }
  }
}
    `;

export function useOrgDatasourcesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrgDatasourcesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrgDatasourcesQuery,
    GQTypes.GQOrgDatasourcesQueryVariables
  >({ query: OrgDatasourcesDocument, ...options });
}
export const GetPermissionsForUserDocument = gql`
    query GetPermissionsForUser($userId: ID!, $orgId: ID!) @withOwner(owner: EnterpriseFoundations) {
  user(id: $userId) {
    id
    name
    email
    displayName
    orgAccessId(orgId: $orgId)
    userUploadTasks {
      id
      measurementProject {
        id
        name
        active
      }
      datasource {
        id
        name
        dataset {
          id
          name
        }
      }
    }
    roles(orgId: $orgId) {
      id
      revokedAt
      role {
        id
        name
        description
        permissions {
          id
          permission
          revokedAt
          objectId
          object {
            __typename
            id
            name
          }
        }
      }
    }
    permissions(orgId: $orgId) {
      id
      ...PermissionFields
    }
  }
}
    ${PermissionFieldsFragmentDoc}`;

export function useGetPermissionsForUserQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetPermissionsForUserQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetPermissionsForUserQuery,
    GQTypes.GQGetPermissionsForUserQueryVariables
  >({ query: GetPermissionsForUserDocument, ...options });
}
export const RevokePermissionDocument = gql`
    mutation RevokePermission($permissionId: ID!) @withOwner(owner: EnterpriseFoundations) {
  revokePermissionItem(input: {permissionItemId: $permissionId}) {
    permissionItem {
      id
      revokedAt
    }
  }
}
    `;

export function useRevokePermissionMutation() {
  return Urql.useMutation<
    GQTypes.GQRevokePermissionMutation,
    GQTypes.GQRevokePermissionMutationVariables
  >(RevokePermissionDocument);
}
export const RevokeRoleForUserDocument = gql`
    mutation RevokeRoleForUser($roleId: ID!, $orgAccessId: ID!, $orgId: ID) @withOwner(owner: EnterpriseFoundations) {
  revokeUserRole(
    input: {roleId: $roleId, orgAccessId: $orgAccessId, orgId: $orgId}
  ) {
    userRoleAssignment {
      id
      revokedAt
    }
  }
}
    `;

export function useRevokeRoleForUserMutation() {
  return Urql.useMutation<
    GQTypes.GQRevokeRoleForUserMutation,
    GQTypes.GQRevokeRoleForUserMutationVariables
  >(RevokeRoleForUserDocument);
}
export const AddPermissionDocument = gql`
    mutation AddPermission($input: CreatePermissionItemInput!) @withOwner(owner: EnterpriseFoundations) {
  createPermissionItem(input: $input) {
    permissionItem {
      id
      user {
        id
      }
      revokedAt
    }
  }
}
    `;

export function useAddPermissionMutation() {
  return Urql.useMutation<
    GQTypes.GQAddPermissionMutation,
    GQTypes.GQAddPermissionMutationVariables
  >(AddPermissionDocument);
}
export const AddUserRoleDocument = gql`
    mutation AddUserRole($input: GrantUserRoleInput!) @withOwner(owner: EnterpriseFoundations) {
  grantUserRole(input: $input) {
    userRoleAssignment {
      id
    }
  }
}
    `;

export function useAddUserRoleMutation() {
  return Urql.useMutation<
    GQTypes.GQAddUserRoleMutation,
    GQTypes.GQAddUserRoleMutationVariables
  >(AddUserRoleDocument);
}
export const GetAllAssignableUsersDocument = gql`
    query GetAllAssignableUsers @withOwner(owner: DataIngestion) {
  organization {
    id
    users {
      edges {
        node {
          ...AssignableUserFields
        }
      }
    }
  }
}
    ${AssignableUserFieldsFragmentDoc}`;

export function useGetAllAssignableUsersQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetAllAssignableUsersQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetAllAssignableUsersQuery,
    GQTypes.GQGetAllAssignableUsersQueryVariables
  >({ query: GetAllAssignableUsersDocument, ...options });
}
export const ParquetDataToCsvDocument = gql`
    mutation ParquetDataToCsv($input: ParquetDataToCsvInput!) @withOwner(owner: DataIngestion) {
  parquetDataToCsv(input: $input) {
    jobId
  }
}
    `;

export function useParquetDataToCsvMutation() {
  return Urql.useMutation<
    GQTypes.GQParquetDataToCsvMutation,
    GQTypes.GQParquetDataToCsvMutationVariables
  >(ParquetDataToCsvDocument);
}
export const GetEngagementTaskConfigsForTaskCreationDocument = gql`
    query GetEngagementTaskConfigsForTaskCreation($includeGlobalConfigs: Boolean!, $orgId: ID!, $includeArchived: Boolean!) @withOwner(owner: SupplyChain) {
  engagementTaskConfigs(
    includeGlobalConfigs: $includeGlobalConfigs
    includeArchived: $includeArchived
    orgId: $orgId
  ) {
    ...EngagementTaskConfigForTaskCreation
  }
}
    ${EngagementTaskConfigForTaskCreationFragmentDoc}`;

export function useGetEngagementTaskConfigsForTaskCreationQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetEngagementTaskConfigsForTaskCreationQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetEngagementTaskConfigsForTaskCreationQuery,
    GQTypes.GQGetEngagementTaskConfigsForTaskCreationQueryVariables
  >({ query: GetEngagementTaskConfigsForTaskCreationDocument, ...options });
}
export const OrderedBusinessActivityTypeInstancesForNameDocument = gql`
    query OrderedBusinessActivityTypeInstancesForName($name: String!) @withOwner(owner: DatasetsAndObjects) {
  orderedBusinessActivityTypeInstancesForName(name: $name) {
    ...BusinessActivityTypeLite
  }
}
    ${BusinessActivityTypeLiteFragmentDoc}`;

export function useOrderedBusinessActivityTypeInstancesForNameQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrderedBusinessActivityTypeInstancesForNameQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrderedBusinessActivityTypeInstancesForNameQuery,
    GQTypes.GQOrderedBusinessActivityTypeInstancesForNameQueryVariables
  >({ query: OrderedBusinessActivityTypeInstancesForNameDocument, ...options });
}
export const LatestBusinessActivityTypeInstancesDocument = gql`
    query LatestBusinessActivityTypeInstances @withOwner(owner: DatasetsAndObjects) {
  latestBusinessActivityTypeInstances {
    ...BusinessActivityTypeLite
  }
}
    ${BusinessActivityTypeLiteFragmentDoc}`;

export function useLatestBusinessActivityTypeInstancesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQLatestBusinessActivityTypeInstancesQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQLatestBusinessActivityTypeInstancesQuery,
    GQTypes.GQLatestBusinessActivityTypeInstancesQueryVariables
  >({ query: LatestBusinessActivityTypeInstancesDocument, ...options });
}
export const LatestBusinessActivityTypeInstanceForNameDocument = gql`
    query LatestBusinessActivityTypeInstanceForName($name: String!) @withOwner(owner: DatasetsAndObjects) {
  latestBusinessActivityTypeInstanceForName(name: $name) {
    ...BusinessActivityTypeLite
  }
}
    ${BusinessActivityTypeLiteFragmentDoc}`;

export function useLatestBusinessActivityTypeInstanceForNameQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQLatestBusinessActivityTypeInstanceForNameQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQLatestBusinessActivityTypeInstanceForNameQuery,
    GQTypes.GQLatestBusinessActivityTypeInstanceForNameQueryVariables
  >({ query: LatestBusinessActivityTypeInstanceForNameDocument, ...options });
}
export const BusinessActivityTypeByIdDocument = gql`
    query BusinessActivityTypeById($batId: ID!) @withOwner(owner: DatasetsAndObjects) {
  businessActivityTypeById(batId: $batId) {
    ...BusinessActivityTypeLite
  }
}
    ${BusinessActivityTypeLiteFragmentDoc}`;

export function useBusinessActivityTypeByIdQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQBusinessActivityTypeByIdQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBusinessActivityTypeByIdQuery,
    GQTypes.GQBusinessActivityTypeByIdQueryVariables
  >({ query: BusinessActivityTypeByIdDocument, ...options });
}
export const BusinessActivityTypeVersionByIdDocument = gql`
    query BusinessActivityTypeVersionById($id: ID!) @withOwner(owner: Calcprint) {
  businessActivityTypeVersionById(id: $id) {
    id
    name
    businessActivityTypes {
      ...AllBusinessActivityTypeFields
    }
  }
}
    ${AllBusinessActivityTypeFieldsFragmentDoc}`;

export function useBusinessActivityTypeVersionByIdQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQBusinessActivityTypeVersionByIdQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBusinessActivityTypeVersionByIdQuery,
    GQTypes.GQBusinessActivityTypeVersionByIdQueryVariables
  >({ query: BusinessActivityTypeVersionByIdDocument, ...options });
}
export const BusinessActivityTypeByIdsDocument = gql`
    query BusinessActivityTypeByIds($batIds: [ID!]!) @withOwner(owner: Calcprint) {
  businessActivityTypeByIds(batIds: $batIds) {
    version {
      id
      name
    }
    ...AllBusinessActivityTypeFields
  }
}
    ${AllBusinessActivityTypeFieldsFragmentDoc}`;

export function useBusinessActivityTypeByIdsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQBusinessActivityTypeByIdsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBusinessActivityTypeByIdsQuery,
    GQTypes.GQBusinessActivityTypeByIdsQueryVariables
  >({ query: BusinessActivityTypeByIdsDocument, ...options });
}
export const LiveBusinessActivityTypeVersionDocument = gql`
    query LiveBusinessActivityTypeVersion @withOwner(owner: Calcprint) {
  businessActivityTypeVersionLive {
    id
    name
    businessActivityTypes {
      ...AllBusinessActivityTypeFields
    }
  }
}
    ${AllBusinessActivityTypeFieldsFragmentDoc}`;

export function useLiveBusinessActivityTypeVersionQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQLiveBusinessActivityTypeVersionQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQLiveBusinessActivityTypeVersionQuery,
    GQTypes.GQLiveBusinessActivityTypeVersionQueryVariables
  >({ query: LiveBusinessActivityTypeVersionDocument, ...options });
}
export const BusinessActivityTypeByNameAndVersionIdDocument = gql`
    query BusinessActivityTypeByNameAndVersionId($name: String!, $versionId: ID!) @withOwner(owner: Calcprint) {
  businessActivityTypeByNameAndVersionId(name: $name, versionId: $versionId) {
    ...BusinessActivityTypeLite
  }
}
    ${BusinessActivityTypeLiteFragmentDoc}`;

export function useBusinessActivityTypeByNameAndVersionIdQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQBusinessActivityTypeByNameAndVersionIdQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQBusinessActivityTypeByNameAndVersionIdQuery,
    GQTypes.GQBusinessActivityTypeByNameAndVersionIdQueryVariables
  >({ query: BusinessActivityTypeByNameAndVersionIdDocument, ...options });
}
export const AllOrgUsersForPermissionDocument = gql`
    query AllOrgUsersForPermission($orgId: ID!, $permissions: [HasPermissionInput!]!) @withOwner(owner: EnterpriseFoundations) {
  organization(id: $orgId) {
    id
    users {
      edges {
        node {
          ...UserForAllOrgUsersForPermission
        }
      }
    }
  }
}
    ${UserForAllOrgUsersForPermissionFragmentDoc}`;

export function useAllOrgUsersForPermissionQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQAllOrgUsersForPermissionQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQAllOrgUsersForPermissionQuery,
    GQTypes.GQAllOrgUsersForPermissionQueryVariables
  >({ query: AllOrgUsersForPermissionDocument, ...options });
}
export const OrgUsersWithPermissionsDocument = gql`
    query OrgUsersWithPermissions($orgId: ID!, $permissionInfos: [HasPermissionInput!]!) @withOwner(owner: EnterpriseFoundations) {
  organization(id: $orgId) {
    id
    users(filters: {withSomePermissions: $permissionInfos}) {
      edges {
        node {
          ...UserForOrgUsersWithPermissions
        }
      }
    }
  }
}
    ${UserForOrgUsersWithPermissionsFragmentDoc}`;

export function useOrgUsersWithPermissionsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQOrgUsersWithPermissionsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQOrgUsersWithPermissionsQuery,
    GQTypes.GQOrgUsersWithPermissionsQueryVariables
  >({ query: OrgUsersWithPermissionsDocument, ...options });
}
export const GetJobResultsDocument = gql`
    query GetJobResults($id: ID!) {
  job: backgroundJob(id: $id) {
    id
    kind
    state
    args
    result
    error {
      ...SerializableErrorFields
    }
  }
}
    ${SerializableErrorFieldsFragmentDoc}`;

export function useGetJobResultsQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetJobResultsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetJobResultsQuery,
    GQTypes.GQGetJobResultsQueryVariables
  >({ query: GetJobResultsDocument, ...options });
}
export const GetJobStatusDocument = gql`
    query GetJobStatus($id: ID!) {
  job: backgroundJobStatus(id: $id) {
    id
    orgId
    state
    error {
      ...SerializableErrorFields
    }
  }
}
    ${SerializableErrorFieldsFragmentDoc}`;

export function useGetJobStatusQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetJobStatusQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetJobStatusQuery,
    GQTypes.GQGetJobStatusQueryVariables
  >({ query: GetJobStatusDocument, ...options });
}
export const ListJobsStatusDocument = gql`
    query ListJobsStatus($ids: [ID!]!) {
  backgroundJobStatuses(ids: $ids) {
    id
    orgId
    state
    error {
      ...SerializableErrorFields
    }
  }
}
    ${SerializableErrorFieldsFragmentDoc}`;

export function useListJobsStatusQuery(
  options: Omit<
    Urql.UseQueryArgs<GQTypes.GQListJobsStatusQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQListJobsStatusQuery,
    GQTypes.GQListJobsStatusQueryVariables
  >({ query: ListJobsStatusDocument, ...options });
}
export const CancelWorkflowDocument = gql`
    mutation CancelWorkflow($input: CancelWorkflowInput!) {
  cancelWorkflow(input: $input) {
    success
  }
}
    `;

export function useCancelWorkflowMutation() {
  return Urql.useMutation<
    GQTypes.GQCancelWorkflowMutation,
    GQTypes.GQCancelWorkflowMutationVariables
  >(CancelWorkflowDocument);
}
export const GetAllReportConfigsDocument = gql`
    query GetAllReportConfigs($filterHidden: Boolean) @withOwner(owner: Reporting) {
  allReportConfigs(filterHidden: $filterHidden) {
    ...OverviewReportConfigFields
  }
}
    ${OverviewReportConfigFieldsFragmentDoc}`;

export function useGetAllReportConfigsQuery(
  options?: Omit<
    Urql.UseQueryArgs<GQTypes.GQGetAllReportConfigsQueryVariables>,
    'query'
  >
) {
  return Urql.useQuery<
    GQTypes.GQGetAllReportConfigsQuery,
    GQTypes.GQGetAllReportConfigsQueryVariables
  >({ query: GetAllReportConfigsDocument, ...options });
}
